<template>
  <div class="order-details">
    <v-row justify="center" align="center">
      <v-col>
        <div class="text-center logo">
          <VuetifyLogo />
        </div>
        <div v-for="item in allProducts" :key="item.id">
          <v-card class="mx-auto" outlined>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="title">
                  {{ item.name }}
                </v-list-item-title>
                <div class="price" dir="rtl">
                  سعر الوحدة: {{ item.price }} ريال
                </div>

                <v-list-item-subtitle>{{
                  item.description
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar
                size="80"
                style="margin-right: 0; margin-left: 15px"
              >
                <!-- <img :src="image_base_url + item.avatar" :alt="item.trans.name" /> -->
                <a href="#" class="img-holder">
                  <img :src="item.avatar" class="bg-cover" :alt="item.name" />
                </a>
              </v-list-item-avatar>
            </v-list-item>
            <div class="d-flex d-rtl">
              <v-btn class="mr-3 btn" rounded text disabled>
                {{ item.mini_order }} {{ item.unit }}
              </v-btn>
              <div class="input">
                <input type="number" disabled v-model="item.quantity" />
              </div>
            </div>
            <div
              class="price"
              dir="rtl"
              style="margin-top: 10px; text-align: center; position: initial"
            >
              الإجمالي الفرعي: {{ item.sub_total }} ريال
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import VuetifyLogo from "../../components/VuetifyLogo.vue";
import { ServiceFactory } from "../../services/ServiceFactory";
const Service = ServiceFactory.get("orders");
export default {
  data() {
    return {
      allOrders: [],
      allProducts: [],
      dataLoading: false,
    };
  },
  components: {
    VuetifyLogo,
  },
  created() {
    if (this.$route) {
      if (localStorage.getItem("token")) {
        this.fetchOrder();
      } else {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    async fetchOrder() {
      console.log();
      this.dataLoading = true;
      const data = await Service.getOrderById(this.$route.params.id);
      this.allProducts = data.data;
      this.dataLoading = false;
    },
  },
};
</script>
<style>
.order-details {
  direction: ltr;
  padding-bottom: 60px;
}
.green-btn {
  background-color: #4c9e53;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  margin: 0 10px 10px 0;
}
.order-details .v-card {
  margin-bottom: 20px;
}
.order-details .title {
  color: #4c9e53;
  font-size: 15px !important;
}
.order-details .price {
  color: #614c37;
  font-weight: 700;
  font-size: 15px;
  position: absolute;
  top: 20px;
}
.order-details .img {
  box-shadow: 0 5px 11px rgba(0, 0, 0, 0.18);
  border: 3px solid #fff;
  border-radius: 50%;
}
.order-details .v-sheet.v-card {
  padding-bottom: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.18);
  background: #fff;
  border-radius: 20px;
}
.order-details
  .v-application--is-ltr
  .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0 !important;
}
.order-details .d-rtl {
  direction: rtl;
}
.order-details .d-rtl .btn {
  background: #fcc013;
  color: #614c37 !important;
  width: 90px;
  padding: 5px;
  text-align: center;
  border-radius: 50px;
  margin-left: 15px;
}
.order-details .d-rtl .input {
  position: relative;
  width: 50%;
}
.v-list-item--three-line .v-list-item__subtitle {
  font-size: 13px;
  color: #767676;
  min-height: 36.8px;
}
.order-details .d-rtl .input input {
  padding: 5px 45px 5px 25px;
  border: 1px solid #555;
  width: 100%;
  border-radius: 7px;
  text-align: center;
}
.order-details .d-rtl .input .add {
  position: absolute;
  right: 21px;
  top: 5px;
  z-index: 20;
  background-color: #fff;
}
.order-details .d-rtl .input .minus {
  position: absolute;
  left: 21px;
  top: 5px;
  z-index: 20;
  background-color: #fff;
}
.logo {
  box-shadow: none !important;
  border: unset !important;
  border-radius: unset !important;
}
</style>
